<template>
  <Browse
    :columns="[
      'name',
      'validity',
      'payment_type',
      'fee',
      'inscription_fee',
      'number_of_clases',
      'activity',
    ]"
    :formats="{
      fee: 'Money',
      inscription_fee: 'Money',
      number_of_clases: 'Integer6',
    }"
    itemsPerPage="10"
    :searchOnStart="true"
    table="plans"
  />
</template>

<script>
import Browse from "@/components/Browse.vue";

export default {
  components: {
    Browse,
  },
};
</script>
